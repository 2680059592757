
import { ref, computed } from 'vue';

import Card from 'primevue/card';

import PhotosCatalog from '@/components/Galleria/PhotosCatalog.vue';

export default {
  components: {
    Card,
    'photos-catalog': PhotosCatalog,
  },
  setup() {
    const photos = ref([]);

    const isMobile = computed(() =>
    {
      return window.innerWidth <= 700;
    });

    return {
      photos,
      isMobile
    }
  }
}
